import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "../slices/users.slice";
import authReducer from "../slices/auth.slice";
import roleReducer from "../slices/role.slice";
import logsReducer from "redux/slices/logs.slice";
import systemInfoReducer from "redux/slices/systemInfo.slice";
import chatReducer from "redux/slices/chat.slice";
import accountReducer from "redux/slices/accounts.slice";
import categoryReducer from "redux/slices/category_slice";
import smsReducer from "redux/slices/sms.slice";
import eventReducer from "redux/slices/eventSlice";
import outMemberReducer from "redux/slices/outMember_slice";
import fileReducer from "redux/slices/file.slice";
import archiveReducer from "redux/slices/archive.slice";
import signReducer from "redux/slices/signature.slice";
import activeUserSlice from "redux/slices/activeUserSlice";
import profileSlice from "redux/slices/profileSlice";
import contractSlice from "redux/slices/contract_slice";
import mdaReducer from "redux/slices/mdaSlice";
import rbookReducer from "redux/slices/rbooks_slice";
import receiptReducer from "redux/slices/receipts_slice";
import fmisReducer from "redux/slices/fmiss_slice";
import deviceReducer from "redux/slices/deviceSlice";
import mdaAgentReducer from "redux/slices/mdaAgentSlice";
import auditStageReducer from "redux/slices/auditStages_slice";
import backupReducer from "redux/slices/backupSlice";
import receiptIssueReducer from "redux/slices/receiptIssue_slice";
import countsReducer from "redux/slices/countsSlice";
import rbooksCountSlice from "redux/slices/rbooks_countSlice";
import receiptsCountSlice from "redux/slices/receiptsCountSlice";
import contractsCountSlice from "redux/slices/contractsCountSlice";
import fmisCountSlice from "redux/slices/fmisCountSlice";
import supportReducer from "redux/slices/support.slice";
import notificationContractReducer from "redux/slices/notification-contracts.slice";
import referenceReducer from "redux/slices/referenceSlice";

import localforage from "localforage";

const persistConfig = {
  keyPrefix: "redux-",
  key: "root",
  storage: localforage,

  whitelist: [
    "auth",
    "user",  
  ],
};

const rootReducer = combineReducers({
  // Add your reducers here
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  logs: logsReducer,
  systemInfo: systemInfoReducer,
  chat: chatReducer,
  account: accountReducer,
  
  category: categoryReducer,
  sms: smsReducer,
  
  event: eventReducer,
  outMember: outMemberReducer,
  
  file: fileReducer,
  archive: archiveReducer,
  signature: signReducer,
  activeUser: activeUserSlice,
  profile: profileSlice,
  
  contract: contractSlice,
  mda: mdaReducer,
  rbook: rbookReducer,
  receipt: receiptReducer,
  fmis: fmisReducer,
  device: deviceReducer,
  mdaAgent: mdaAgentReducer,
  auditStage: auditStageReducer,
  backup: backupReducer,
  receiptIssue: receiptIssueReducer,
  counts: countsReducer,
  rbooksCounts: rbooksCountSlice,
  receiptsCounts: receiptsCountSlice,
  contractsCounts: contractsCountSlice,
  fmisCounts: fmisCountSlice,
  support: supportReducer,
  notificationContract: notificationContractReducer,
  reference: referenceReducer,

});

export { persistConfig, rootReducer };
